import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Dropdown } from 'semantic-ui-react';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

/*Action */
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { tpayRegister, tpayLogin, empty, signOut } from '../../../Actions/AuthActions';
import { fitRegister, fitLogin } from '../../../Actions/AuthActions';
import {
    addSeriesIDtoRedirectionPage,
    emptyExchangeData, redirectionPage
} from '../../../Actions/RedirectionPagesActions'
import { getTelecomPrices } from '../../../Actions/FawryPaymentAction'
import { getDigest, emptyTpay } from '../../../Actions/TpayActions';

/*component */
import mainLogoM from '../../../images/portal/logo-big@2x.png';
import vodafone from '../../../images/tpay/vodafone.png'
import we from '../../../images/tpay/we.jpg'
import orange from '../../../images/tpay/orange.png'
import etisalat from '../../../images/tpay/etisalat.png'
import stc from '../../../images/fit/stc.png'
import mobily from '../../../images/fit/mobily.jpg'
import zain from '../../../images/fit/zain.jpg'

import IraqFlag from '../../../images/mw/IraqFlag.jpg'
import bahrainFlag from '../../../images/mw/bahrainFlag.png'
import qatarFlag from '../../../images/mw/qatarFlag.png'
import libyaFlag from '../../../images/mw/libyaFlag.png'

import Footer from '../Layout/Footer';
import Testimonials from '../Layout/Testimonials';
import Loader from '../../../components/Layout/Loader'
import "../../../css/Fit.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Routes */
import { PORTAL_LOGIN_METHODS } from '../../../routes';

import * as CONSTANTS from '../../../constants';

const TPAY_SERVICES = [
    {
        key: 0,
        text: CONSTANTS.JEEL_Orange,
        value: CONSTANTS.TPAY_ORANGE,
        image: { avatar: true, src: orange },
    },
    {
        key: 1,
        text: CONSTANTS.JEEL_Vodafone,
        value: CONSTANTS.TPAY_VODAFONE,
        image: { avatar: true, src: vodafone },
    },
    {
        key: 2,
        text: CONSTANTS.JEEL_We,
        value: CONSTANTS.TPAY_WE,
        image: { avatar: true, src: we },
    },
    {
        key: 3,
        text: CONSTANTS.JEEL_Etisalat,
        value: CONSTANTS.TPAY_ETISALAT,
        image: { avatar: true, src: etisalat },
    }
]

const FIT_SERVICES = [
    {
        key: 0,
        text: 'STC',
        value: CONSTANTS.FIT_STC,
        image: { avatar: true, src: stc },
    },
    {
        key: 1,
        text: 'ZAIN - زين',
        value: CONSTANTS.FIT_ZAIN,
        image: { avatar: true, src: zain },
    },
    {
        key: 2,
        text: 'MOBILY',
        value: CONSTANTS.FIT_MOBILY,
        image: { avatar: true, src: mobily },
    },
]

const MW_Iraq_SERVICES = [
    {
        key: 0,
        text: 'ZAIN IRAQ',
        value: CONSTANTS.MW_ZAIN_IRAQ,
        image: { avatar: true, src: IraqFlag },
    },
]

const MW_Bahrain_SERVICES = [
    {
        key: 0,
        text: 'Batelcom_Bahrain',
        value: CONSTANTS.MW_BATELCO_BAHRAIN,
        image: { avatar: true, src: bahrainFlag },
    },
]

const MW_Qatar_SERVICES = [
    {
        key: 0,
        text: 'Vodaphone_Qatar',
        value: CONSTANTS.MW_VODAPHONE_QATAR,
        image: { avatar: true, src: qatarFlag },
    },
]

const MW_Libya_SERVICES = [
    {
        key: 0,
        text: 'Libyana_Libya',
        value: CONSTANTS.MW_LIBYANA_LIBYA,
        image: { avatar: true, src: libyaFlag },
    },
    {
        key: 1,
        text: 'Almadar_Libya',
        value: CONSTANTS.MW_ALMADAR_LIBYA,
        image: { avatar: true, src: libyaFlag },
    },    
]

const COUNTRIES = [
    {
        text: 'مصر',
        value: 'Egypt'
    },
    {
        text: 'المملكة العربية السعودية',
        value: 'Saudi Arabia'
    },
    {
        text: 'العراق',
        value: 'Iraq'
    },
    
    /*
        {
        text: 'البحرين',
        value: 'Bahrain'
   },
        {
        text: 'قطر',
        value: 'Qatar'
   },
   */
               {
        text: 'ليبيا',
        value: 'Libya'
   },
   
  
]

const DailyWeeklyPeriodList=[{text:'Daily',value:'Daily'},{text:'Weekly',value:'Weekly'}]

const TPAY_SERVICES_ARRAY = [
    CONSTANTS.TPAY_ORANGE, CONSTANTS.TPAY_VODAFONE, CONSTANTS.TPAY_WE, CONSTANTS.TPAY_ETISALAT
]

const FIT_SERVICES_ARRAY = [
    CONSTANTS.FIT_STC, CONSTANTS.FIT_MOBILY, CONSTANTS.FIT_ZAIN
]

const MW_Iraq_SERVICES_ARRAY = [
    CONSTANTS.MW_ZAIN_IRAQ
]
const MW_Bahrain_SERVICES_ARRAY = [
    CONSTANTS.MW_BATELCO_BAHRAIN
]
const MW_Qatar_SERVICES_ARRAY = [
    CONSTANTS.MW_VODAPHONE_QATAR
]
const MW_Libya_SERVICES_ARRAY = [
    //CONSTANTS.MW_LIBYANA_LIBYA,CONSTANTS.MW_ALMADAR_LIBYA,
]

class TelecomLogin extends Component {

    state = {
        register: true,
        email: "",
        date: "",

        country: 'Egypt',
        phone: "",
        telecomCompany: "JEEL_Orange",
        activeRegisterBtn: 'active',
        activeSignInBtn: '',
        activeItemIndex: 0,
        disabled: true,
        loginDisabled: true,
        errorMsg: '',
        error: false,

        openSnakBar: false,
        vertical: 'bottom',
        horizontal: 'right',

        isRevealPassword: false,
        password: "",

        redirect: false,
        loginSuccess: false,

        services: [],
        countryPhoneCode: 'eg',
        subscriptionMessage: '',

        telecomCompanySubscriptionPrice: '',
        frequency: '',
        keyword: CONSTANTS.TPAY_KEYWORDS[CONSTANTS.TPAY_ORANGE],
        shortCode: CONSTANTS.TPAY_SHORT_CODES[CONSTANTS.TPAY_ORANGE],
        supportMail: CONSTANTS.SUPPORT_MAIL,
        serviceName: CONSTANTS.JEEL_SERVICE_NAME,
        telecomCompanyText: 'اورانج - Orange',

        digest: '',
        loaded: false,
        sessionToken: '',
        paymentTelecomName:'',
        
        dailyWeeklyListSelectedValue:'Daily',
        dailyWeeklyListDisplayFlag:false,

    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //console.log("Signout Telecom")
        const searchParams = new URLSearchParams(window.location.search);
        const paymentTelecome = searchParams.get('name');
        
											        const paymentTelecomeFlag = searchParams.get('countryName');
											        
											        if(paymentTelecomeFlag!=null && paymentTelecomeFlag!=undefined && paymentTelecomeFlag.length > 0 && paymentTelecomeFlag==("libya"))
											        {
												        let libObj = {
																		text: 'ليبيا',
												        				value: 'Libya'	
																	 };
														if(COUNTRIES.length < 4)
														{
															COUNTRIES[3] = libObj;
														}			 
														
												        console.log('COUNTRIES: '+JSON.stringify(libObj,null,2)+"  ,len:"+COUNTRIES.length);        
											        }
        
        this.props.signOut();
        this.props.emptyTpay();
        this.props.empty();
        // empty redirection page
        this.props.redirectionPage();
        this.props.getTelecomPrices("Egypt", "JEEL_Orange");

        //date send
        var date = new Date();
        var utc_date = date.toISOString();
        this.props.getDigest(utc_date);
        this.passwordOneRef = React.createRef();
        this.iconRevealPassword = React.createRef();
        this.setState({
            digest: '',
            date: utc_date,
        })
        if(paymentTelecome !== null && paymentTelecome !== ''){
            this.setState({
                paymentTelecomName:paymentTelecome,
                telecomCompany: paymentTelecome,
                telecomCompanyText: CONSTANTS[paymentTelecome],
                shortCode: CONSTANTS.TPAY_SHORT_CODES[paymentTelecome],
                keyword: CONSTANTS.TPAY_KEYWORDS[paymentTelecome],
            })

            if(paymentTelecome === CONSTANTS.FIT_STC || 
               paymentTelecome === CONSTANTS.FIT_MOBILY ||
               paymentTelecome === CONSTANTS.FIT_ZAIN){
                this.setState({
                    services: FIT_SERVICES,
                    country: "Saudi Arabia",
                    countryPhoneCode: 'sa'
        
                })                
            }else if(paymentTelecome === CONSTANTS.PAYMENT_METHOD_TELECOM_IRAQ_ZAIN){
                this.setState({
                    services: MW_Iraq_SERVICES,
                    country: "IRAQ",
                    countryPhoneCode: 'iq'
        
                })                
            }
			else if(paymentTelecome === CONSTANTS.PAYMENT_METHOD_TELECOM_LIBYA_LIBYANA){
                this.setState({
                    services: MW_Libya_SERVICES,
                    country: "LIBYA",
                    countryPhoneCode: 'ly'
        
                })                
            }
            else if(paymentTelecome === CONSTANTS.PAYMENT_METHOD_TELECOM_LIBYA_MADAR){
                this.setState({
                    services: MW_Libya_SERVICES,
                    country: "LIBYA",
                    countryPhoneCode: 'ly'
        
                })                
            }
			else if(paymentTelecome === CONSTANTS.PAYMENT_METHOD_TELECOM_QATAR_VODAPHONE){
                this.setState({
                    services: MW_Qatar_SERVICES,
                    country: "QATAR",
                    countryPhoneCode: 'qa'
        
                })                
            }            
            else{
                this.setState({
                    services: TPAY_SERVICES,
                    country: "Egypt",
                    countryPhoneCode: 'eg'
        
                })
            }

       }
    }

    static getDerivedStateFromProps(props, state) {
	
	
	//console.log('in derived: '+JSON.stringify(state,null,3));
	
        // check props for digest
        if (props.digest !== state.digest) {
            return {
                digest: props.digest,
            }
        }
        
        if (props.packagePrice && state.country === CONSTANTS.EGYPT) {
            return {
                subscriptionMessage: 'قيمة الاشتراك الشهري هي ' + props.packagePrice.monthDiscountPrice + ' جنيهًا مصريًا، تجدد تلقائيًا كل شهر. ',
                telecomCompanySubscriptionPrice: props.packagePrice.monthDiscountPrice,
                frequency: CONSTANTS.PORTAL_SUBSCRIPTION_MONTHLY,
            }
        } else if (state.country === CONSTANTS.SAUDI_ARABIA) {
            if (state.telecomCompany === CONSTANTS.FIT_ZAIN) {
                return {
                    subscriptionMessage: CONSTANTS.ZAIN_SUBSCRIPTION_MESSAGE,
                }
            } else if (state.telecomCompany === CONSTANTS.FIT_MOBILY) {
                return {
                    subscriptionMessage: CONSTANTS.MOBILY_SUBSCRIPTION_MESSAGE,
                }
            } else if (state.telecomCompany === CONSTANTS.FIT_STC) {
                return {
                    subscriptionMessage: CONSTANTS.STC_SUBSCRIPTION_MESSAGE,
                }
            }
        } else if (state.country === CONSTANTS.IRAQ) {
            if (state.telecomCompany === CONSTANTS.MW_ZAIN_IRAQ) 
            {
                return {
                    subscriptionMessage: CONSTANTS.MW_ZAIN_IRAQ_SUBSCRIPTION_MESSAGE,
                }
            
            }
        }
        else if (state.country === CONSTANTS.BAHRAIN) {	
            if (state.telecomCompany === CONSTANTS.MW_BATELCO_BAHRAIN) 
            {
                return {
                    subscriptionMessage: CONSTANTS.MW_BATELCON_BAHRAIN_SUBSCRIPTION_MESSAGE,
                }
            
            }
        }
        else if (state.country === CONSTANTS.QATAR) {	
            if (state.telecomCompany === CONSTANTS.MW_VODAPHONE_QATAR) 
            {
                return {
                    subscriptionMessage: CONSTANTS.MW_VODAPHONE_QATAR_SUBSCRIPTION_MESSAGE,
                }
            
            }
        }
        
        else if (state.country === CONSTANTS.LIBYA) {	
            if (state.telecomCompany === CONSTANTS.MW_LIBYANA_LIBYA) 
            {
                return {
                    subscriptionMessage: CONSTANTS.MW_LIBYANA_LIBYA_SUBSCRIPTION_MESSAGE,
                }            
            }
            else if (state.telecomCompany === CONSTANTS.MW_ALMADAR_LIBYA) 
            {
                return {
                    subscriptionMessage: CONSTANTS.MW_ALMADAR_LIBYA_SUBSCRIPTION_MESSAGE,
                }            
            }
        }        
        
        if (state.countryPhoneCode === CONSTANTS.EGYPT_SHORT_CODE) {
            return {
                country: CONSTANTS.EGYPT,
                services: TPAY_SERVICES,
                telecomCompany: CONSTANTS.TPAY_ORANGE,
                telecomCompanyText: CONSTANTS.JEEL_Orange,
            }
        } else if (state.countryPhoneCode === CONSTANTS.SAUDI_ARABIA_SHORT_CODE) {
            return {
                country: CONSTANTS.SAUDI_ARABIA,
                services: FIT_SERVICES,
                telecomCompany: CONSTANTS.FIT_STC
            }
        }  else if (state.countryPhoneCode === CONSTANTS.IRAQ_SHORT_CODE) {
            return {
                country: CONSTANTS.IRAQ,
                services: MW_Iraq_SERVICES,
                telecomCompany: CONSTANTS.MW_ZAIN_IRAQ
            }
        }
        else if (state.countryPhoneCode === CONSTANTS.BAHRAIN_SHORT_CODE) {
            return {
                country: CONSTANTS.BAHRAIN,
                services: MW_Bahrain_SERVICES,
                telecomCompany: CONSTANTS.MW_BATELCO_BAHRAIN
            }
        }
        else if (state.countryPhoneCode === CONSTANTS.QATAR_SHORT_CODE) {
            return {
                country: CONSTANTS.QATAR,
                services: MW_Qatar_SERVICES,
                telecomCompany: CONSTANTS.MW_VODAPHONE_QATAR
            }
        }
        else if (state.countryPhoneCode === CONSTANTS.LIBYA_SHORT_CODE) {
            return {
                country: CONSTANTS.LIBYA,
                services: MW_Libya_SERVICES,
                telecomCompany: CONSTANTS.MW_LIBYANA_LIBYA
            }
        }        
    }

    loadScript(src) {
        var script = document.createElement('script');
        script.async = false;
        script.src = src;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(script);
        this.setState({
            loaded: true
        })
        //console.log("load script Tpay");
        script.addEventListener("load", this.handleScript.bind(this));
        script.addEventListener("error", this.handleScript);

    }

    handleScript(e) {
        if (e.type === "load") {

            const sessionToekn = window.getSessionToken();
            //console.log(sessionToekn);
            this.setState({
                sessionToken: sessionToekn
            });
        } else {
            //console.log(e);
        }
    }

    handleRegisterForm = () => {
        this.setState({ register: true, activeRegisterBtn: 'active', activeSignInBtn: '' });
    }

    handleSignInForm = () => {
        this.setState({ register: false, activeRegisterBtn: '', activeSignInBtn: 'active' });
    }

    handlePhoneLoginChange = (e, country) => {
        this.setState({ phone: e, country: country.name });
        if (country.name === CONSTANTS.EGYPT) {
            this.setState({
                services: TPAY_SERVICES,
                countryPhoneCode: CONSTANTS.EGYPT_SHORT_CODE
            });
            if (!TPAY_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({
                    telecomCompany: CONSTANTS.TPAY_ORANGE,
                    telecomCompanyText: CONSTANTS.JEEL_Orange
                })
            }
        } else if (country.name == CONSTANTS.SAUDI_ARABIA) {
            this.setState({
                services: FIT_SERVICES,
                countryPhoneCode: CONSTANTS.SAUDI_ARABIA_SHORT_CODE
            });
            if (!FIT_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.FIT_STC })
            }
        } else if (country.name == CONSTANTS.IRAQ) {
            this.setState({
                services: MW_Iraq_SERVICES,
                countryPhoneCode: CONSTANTS.IRAQ_SHORT_CODE
            });
            if (!MW_Iraq_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_ZAIN_IRAQ })
            }
        }else if (country.name == CONSTANTS.BAHRAIN) {
            this.setState({
                services: MW_Bahrain_SERVICES,
                countryPhoneCode: CONSTANTS.BAHRAIN_SHORT_CODE
            });
            if (!MW_Bahrain_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_BATELCO_BAHRAIN })
            }
        } else if (country.name == CONSTANTS.QATAR) {
            this.setState({
                services: MW_Qatar_SERVICES,
                countryPhoneCode: CONSTANTS.QATAR_SHORT_CODE
            });
            if (!MW_Qatar_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_VODAPHONE_QATAR })
            }
        } else if (country.name == CONSTANTS.LIBYA) {
            this.setState({
                services: MW_Libya_SERVICES,
                countryPhoneCode: CONSTANTS.LIBYA_SHORT_CODE
            });
            if (!MW_Libya_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_LIBYANA_LIBYA })
            }
        } 
        else {
            this.setState({ country: '' });
        }
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' && this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '

            ) {
                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handlePhoneRegisterChange = (e, country) => {
        this.setState({
            phone: e,
            country: country.name
        });
        
        if (country.name === CONSTANTS.EGYPT) {
            this.setState({
                services: TPAY_SERVICES,
                countryPhoneCode: CONSTANTS.EGYPT_SHORT_CODE
            });
            if (!TPAY_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({
                    telecomCompany: CONSTANTS.TPAY_ORANGE,
                    telecomCompanyText: CONSTANTS.JEEL_Orange
                })
            }

        } else if (country.name == CONSTANTS.SAUDI_ARABIA) {
            this.setState({
                services: FIT_SERVICES,
                countryPhoneCode: CONSTANTS.SAUDI_ARABIA_SHORT_CODE
            });
            if (!FIT_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.FIT_STC })
            }
        } 
        else if (country.name == CONSTANTS.IRAQ) {
            this.setState({
                services: MW_Iraq_SERVICES,
                countryPhoneCode: CONSTANTS.IRAQ_SHORT_CODE
            }); 
            if (!MW_Iraq_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_ZAIN_IRAQ })
            }
        } else if (country.name == CONSTANTS.BAHRAIN) {	
            this.setState({
                services: MW_Bahrain_SERVICES,
                countryPhoneCode: CONSTANTS.BAHRAIN_SHORT_CODE
            }); 
            if (!MW_Bahrain_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_BATELCO_BAHRAIN })
            }
        } else if (country.name == CONSTANTS.QATAR) {	
            this.setState({
                services: MW_Qatar_SERVICES,
                countryPhoneCode: CONSTANTS.QATAR_SHORT_CODE
            }); 
            if (!MW_Qatar_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_VODAPHONE_QATAR })
            }
        }else if (country.name == CONSTANTS.LIBYA) {	
            this.setState({
                services: MW_Libya_SERVICES,
                countryPhoneCode: CONSTANTS.LIBYA_SHORT_CODE
            }); 
            if (!MW_Libya_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({ telecomCompany: CONSTANTS.MW_LIBYANA_LIBYA })
            }
        } else {
            this.setState({ country: '' });
        }
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' && this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '
            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);

    }

    handleTelecomCompany = (e, { value }) => {
        if(this.state.paymentTelecomName !== null &&
            this.state.paymentTelecomName !== '' ){ // this condition to not select any telecomCompanyText if it is selected 
            return;
        }
        let c = value;
        this.setState({
            telecomCompany: value,
            telecomCompanyText: CONSTANTS[c],
            shortCode: CONSTANTS.TPAY_SHORT_CODES[c],
            keyword: CONSTANTS.TPAY_KEYWORDS[c],
        })
    }

    handleCountryChange = (e, { value }) => {

        if(this.state.paymentTelecomName  !== null &&
            this.state.paymentTelecomName  !== ''){ // this condition to not select any telecomCompanyText if it is selected 
            return;
        }

        let countryInput = value;
        this.setState({
            country: value
        })
        if (countryInput === 'Egypt') {
            this.setState({ services: TPAY_SERVICES, countryPhoneCode: 'eg', telecomCompany: CONSTANTS.TPAY_ORANGE,dailyWeeklyListDisplayFlag:false });
        } else if (countryInput === 'Saudi Arabia') {
            this.setState({ services: FIT_SERVICES, countryPhoneCode: 'sa', telecomCompany: CONSTANTS.FIT_STC,dailyWeeklyListDisplayFlag:false });
        } else if (countryInput === 'Iraq') {
            this.setState({ services: MW_Iraq_SERVICES, countryPhoneCode: 'iq', telecomCompany: CONSTANTS.MW_ZAIN_IRAQ,dailyWeeklyListDisplayFlag:false });            
        } else if (countryInput === 'Bahrain') {	
            this.setState({ services: MW_Bahrain_SERVICES, countryPhoneCode: 'bh', telecomCompany: CONSTANTS.MW_BATELCO_BAHRAIN,dailyWeeklyListDisplayFlag:false });            
        } else if (countryInput === 'Qatar') {	
            this.setState({ services: MW_Qatar_SERVICES, countryPhoneCode: 'qa', telecomCompany: CONSTANTS.MW_VODAPHONE_QATAR,dailyWeeklyListDisplayFlag:true });
        }else if (countryInput === 'Libya') {	
            this.setState({ services: MW_Libya_SERVICES, countryPhoneCode: 'ly', telecomCompany: CONSTANTS.MW_LIBYANA_LIBYA,dailyWeeklyListDisplayFlag:false });
        }else {
            this.setState({ services: '', countryPhoneCode: '' });
        }
    }   

    handleDailyWeeklyListChange = (e, { value }) => {
	
	//console.log('DailyWeeklySelectedVal :'+value);
        this.setState({
            dailyWeeklyListSelectedValue: value
        }) ;
                
    }

    handleRegisterChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '


            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '

            ) {


                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handleRegisterSubmit = (e) => {
        const { email, password,phone,telecomCompany, country, sessionToken,dailyWeeklyListSelectedValue } = this.state;
            //alert(JSON.stringify(this.state,null,5));
            //alert('sessionToken: '+sessionToken);
        /// TODO: fix this issue
        //commented because it causes compilation issue
        if (sessionToken != '' || country!=CONSTANTS.EGYPT) {
            // call Register API            
            this.props.tpayRegister(email, password, phone, telecomCompany, country, sessionToken,dailyWeeklyListSelectedValue);
            this.setState({ loginSuccess: true })                         
        }
        e.preventDefault();
    }

    handleLoginSubmit = (e) => {
        const { email, password, phone, telecomCompany, country, sessionToken,dailyWeeklyListSelectedValue } = this.state;

        /// TODO: fix this issue
        //commented because it causes compilation issue
        //var sessionToken = TPay.HeaderEnrichment.sessionToken();
        // call Login API
        if (sessionToken != ''|| country!=CONSTANTS.EGYPT) {
            this.props.tpayLogin(email, password, phone, telecomCompany, country, sessionToken,dailyWeeklyListSelectedValue);

            this.setState({ loginSuccess: true })
        }
        e.preventDefault();
    }

    togglePassword = event => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword })
    }

    redirect = event => {

        if (
            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ') {

            var path = "telecomLogin";
            this.props.addSeriesIDtoRedirectionPage(path, this.state.phone);
            this.setState({ redirect: true });

        } else {
            this.setState({ errorMsg: "يرجى ادخال رقم الهاتف اولا" });

        }
    }


    render() {

        const { isRevealPassword,loginSuccess, redirect, phone, country, telecomCompany,dailyWeeklyListDisplayFlag } = this.state;

        let loader = null;
        let tpaySubContractCode, tpayTransactionCode;

        const { telecom, loading,
            success, errorResponse } = this.props;

        if (redirect === true) {
            return (<Redirect to={PORTAL_LOGIN_METHODS}></Redirect>)
        }

//console.log('login method: ',+'PORTAL_LOGIN_METHODS');

        if (loading === true) {
            loader = (<Loader />)
        }

        if (success === true &&
            loginSuccess === true &&
            telecom !== undefined &&
            telecom !== null) {
            tpaySubContractCode = telecom.subscriptionContractId;
            tpayTransactionCode = telecom.transactionId;

		    
									            if (country === 'Egypt') 
									            {
									                // Add subscription Api Returns error in json when operationStatusCode !=0 
									                if (telecom.operationStatusCode !== 0) {
									
									                    let errorMsg = " لقد حدث خطأ  " + telecom.errorMessage
									
									                    if (telecom.errorMessage.includes('This user already subscribed to the given product')) {
									                        errorMsg = 'هذا الرقم مشترك بالفعل في خدمتنا.'
									                    } else if (telecom.errorMessage.includes('Please enter valid phone number for the selected mobile operator.')) {
									                        errorMsg = 'الرجاء إدخال رقم هاتف صالح لمشغل الشبكة المحددة.'
									                    } else if (telecom.errorMessage.includes("MSISDN Can't be null")) {
									                        errorMsg = 'رقم الموبايل مطلوب ولا يمكن تركه فارغًا. '
									                    } else if (telecom.errorMessage.includes('mobile number is blacklisted')) {
									                        errorMsg = 'عذرًا ، لا يمكن تنشيط رقم الموبايل هذا.'
									                    } else if (telecom.errorMessage.includes('is duplicate, wait for 2 minutes before issuing same request')) {
									                        errorMsg = 'عذرًا ، لم نتمكن من إكمال تسجيلك ، انتظر بضع دقائق وحاول مرة أخرى.'
									                    } else if (telecom.errorMessage.includes('for same customer was cancelled or suspended previously and cool off period did not pass')) {
									                        errorMsg = 'عذرًا ، لا يمكنك الاشتراك ، يرجى إعادة المحاولة بعد يوم واحد.'
									                    }
									
									                    this.setState({ errorMsg: errorMsg });
									
									                    this.props.empty();
									                    this.props.emptyExchangeData();
									                }
									                else {
									                    let message = 'تم تسجيل البيانات بنجاح'
									                    this.props.addSuccessMessage(message);
									                    this.props.empty();
									                    this.props.emptyExchangeData();
									                    let redirect = "/TpayPhoneCode/" + phone + "/" + tpaySubContractCode + "/" + tpayTransactionCode + "/" + telecomCompany;
									                    return (<Redirect path to={redirect}> </Redirect>);
									                }
									            } 
									            else if (country === 'Saudi Arabia') 
									            {
									                let message = 'تم تسجيل البيانات بنجاح'
									                this.props.addSuccessMessage(message);
									                this.props.empty();
									                this.props.emptyExchangeData();
									                let redirect = "/FitVCode/" + telecomCompany + "/" + phone;
									                return (<Redirect path to={redirect}> </Redirect>);
									            }
									            else if (country === 'Iraq') 
									            {
										//console.log('in Iraq');
									                let message = 'تم تسجيل البيانات بنجاح'
									                this.props.addSuccessMessage(message);
									                this.props.empty();
									                //this.props.emptyExchangeData();
									                //let redirect = "/FitVCode/" + telecomCompany + "/" + phone;
									                let redirect = "/MwZainIraqVCode/" + telecomCompany + "/" + phone;
									   //console.log('will go to comp MWCODE 3 & new URL is: '+redirect);                
									                 
									                return (<Redirect path to={redirect}> </Redirect>);
									            }
										else if (country === 'Bahrain') 
									            {
										//console.log('in Bahrain');
									                let message = 'تم تسجيل البيانات بنجاح'
									                this.props.addSuccessMessage(message);
									                this.props.empty();
									                                
									                let redirect = "/MwBahrainVCode/" + telecomCompany + "/" + phone;
									   //console.log('will go to comp MWCODE 3 & new URL is: '+redirect);                
									                 
									                return (<Redirect path to={redirect}> </Redirect>);
									            }    
									           else if (country === 'Qatar') 
									            {
										//console.log('in Qatar');
									                let message = 'تم تسجيل البيانات بنجاح'
									                this.props.addSuccessMessage(message);
									                this.props.empty();
									                                
									                let redirect = "/MwQatarVCode/" + telecomCompany + "/" + phone;
									   //console.log('will go to comp MWCODE 3 & new URL is: '+redirect);                
									                 
									                return (<Redirect path to={redirect}> </Redirect>);
									            }  
											  else if (country === 'Libya') 
									            {
										//console.log('in Libya');
									                let message = 'تم تسجيل البيانات بنجاح'
									                this.props.addSuccessMessage(message);
									                this.props.empty();
									                                
									                //let redirect = "/MwLibyaVCode/" + telecomCompany + "/" + phone;
									                let redirect = "";
									   //console.log('will go to comp MWCODE 3 & new URL is: '+redirect);                
									                 
									                //return (<Redirect path to={redirect}> </Redirect>);
									                //window.location.replace("https://offers.mobibox.mobi/?deviceId=133407076474127698&country=ly&serviceid=2866&lang=EN");
									                let libyaUrl = "https://alma-jal.com/lp/?SMCampaign=ed719651-0908-436a-94a2-bdb946e4252d&ClickID=YOUR_CLICK_ID&Pub_ID=YOUR_PUB_ID&Aff_ID=YOUR_AFF_ID&extra=YOUR_Dynamic_Parameter_IF_Needed&extra1=YOUR_Dynamic_Parameter_IF_Needed&utm_source=affiliate&utm_medium=cpc&utm_campaign={campaignid}&utm_content={adgroupid}&utm_term={keyword}&country=ly&dial=";
									                libyaUrl=libyaUrl+phone;
									                //alert('libyaUrl: '+libyaUrl);
									                window.location.replace(libyaUrl);
									                
									            } 
									            									            
        }
        // erorr in calling api
        else if (success === false &&  (errorResponse !== '' && errorResponse !== undefined && loginSuccess === true)    ) 
        {
									            if (errorResponse === "308 PERMANENT_REDIRECT") 
									            {
									                return (<Redirect path to={'/subscription'}> </Redirect>);
									            }
									            this.setState({ errorMsg: errorResponse });
									            //console.log(errorResponse)
									            this.props.empty();
									            this.props.emptyExchangeData();

        }

        let registerForm = '';
        let signinForm = '';
        let phoneSection = '';
        
        let countrySection = (
						            <div className="telecom-phone-card">
						                <div className="" >
						                    <strong> اختر البلد
						                    </strong>
						                    <br /><br />
						                    <div id="right-lift">
						                        <Dropdown required
						                            placeholder="البلد"
						                            fluid
						                            selection
						                            options={COUNTRIES}
						                            onChange={this.handleCountryChange.bind(this)}
						                            value={this.state.country}
						                        />
						                    </div>
						                </div>
						            </div>
        					)

		let dailyWeeklyComboSection='';
		
		if(dailyWeeklyListDisplayFlag)
		{
									    dailyWeeklyComboSection = (
								            <div className="telecom-phone-card">
								                <div className="" >
								                    <strong> نوع الخدمة
								                    </strong>
								                    <br /><br />
								                    <div id="right-lift">
								                        <Dropdown 
								                            placeholder=""
								                            fluid
								                            selection
								                            options={DailyWeeklyPeriodList}
								                            onChange={this.handleDailyWeeklyListChange.bind(this)}
								                            value={this.state.dailyWeeklyListSelectedValue}
								                        />
								                    </div>
								                </div>
								            </div>
								        )
		}
        

        let loginactive = this.state.loginDisabled

        if (this.state.email !== '' && this.state.email !== null
            && this.state.email !== undefined && this.state.email !== ' ' &&

            this.state.password !== '' && this.state.password !== null
            && this.state.password !== undefined && this.state.password !== ' ' &&

            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ' &&

            this.state.telecomCompany !== '' && this.state.telecomCompany !== null
            && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '
        ) {
            loginactive = false
        }

        let buttonLoginLoader = (
            <div className="custom-form-group w-100 mb-3">
                <button disabled={loginactive}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleLoginSubmit} >
                    تفعيل التسجيل
                </button>
            </div>
        );

        let buttonSubmitLoader = (
            <div className="custom-form-group">
                <button disabled={this.state.disabled}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleRegisterSubmit} >
                    تفعيل التسجيل
                
                </button>
            </div>
        );

		let telecomCompanyDropId = '';
		
		
		if(this.state.countryPhoneCode != 'ly')
		{
			//console.log('Libya Not choosen');
			
			telecomCompanyDropId=(
				                        <Dropdown required fluid selection
				                            placeholder='شركه الاتصالات التابعة لرقمك'
				                            className="phoneNum" id="right-lift"
				                            onChange={this.handleTelecomCompany}
				                            options={this.state.services}
				                            value={this.state.telecomCompany}
				                        />
								  );
		}
		

        if (this.state.register === true) 
        {

            phoneSection = (
				                <div className=" telecom-phone-card " >
				
				                    <div className="" >
				                        <strong>   رقم الهاتف الذي تود الاشتراك به
				                        </strong>
				                        <br /><br />
				                        <div className="phoneNum" id="right-lift">
				                            <PhoneInput
				                                country={this.state.countryPhoneCode}
				                                placeholder="رقم الهاتف"
				                                name="phone"
				                                value={this.state.phone}
				                                onlyCountries={[CONSTANTS.EGYPT_SHORT_CODE, CONSTANTS.SAUDI_ARABIA_SHORT_CODE,CONSTANTS.IRAQ_SHORT_CODE,CONSTANTS.BAHRAIN_SHORT_CODE,CONSTANTS.QATAR_SHORT_CODE,CONSTANTS.LIBYA_SHORT_CODE]}
				                                onChange={this.handlePhoneRegisterChange.bind(this)}
				                            />
				
				                        </div>
				                        <br />
				<br /> 
			
										
										{telecomCompanyDropId}
				                    </div>
				                </div>
				            )

let emailStyleInline = {"text-align":'left'};            //style={emailStyleInline} 

            registerForm = (
				                <form className="signup-form">
				
				                    <div className="form-group">
				                        <input id="font" dir="auto"
				                            type="email"
				                            className="filed form-control"
				                            name="email"
				                            placeholder="بريدك الإلكتروني"
				                            value={this.state.email}
				                            onChange={this.handleRegisterChange}
				                            
				                            />
				                    </div>
				
				                    <div className="form-group">
				
				                        <div className="input-container" style={{ 'position': 'relative' }}>
				                            <input id="font"
				                                type={isRevealPassword ? "text" : "password"}
				                                dir="auto"
				                                className="filed form-control"
				                                name="password"
				                                placeholder="كلمة المرور"
				                                value={this.state.password}
				                                onChange={this.handleRegisterChange}
				                                ref={this.passwordOneRef} 
				                                />
				
				                            <span onClick={this.togglePassword} ref={this.iconRevealPassword} >
				                                <span  >
				                                    {isRevealPassword ?
				                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
				                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
				                                    }
				                                </span>
				
				                            </span>
				                        </div>
				
				                    </div>
				
				
				
				                    <div className="policies">
				                        <div className="text">
				                            <p>بتسجيلك فانت توافق على <span className="color-font">
				                               <a href="/Privacy"> شروط الاستخدام و سياسه الخصوصيه
										      </a>
										
									    
				                            </span> </p>
				
				                        </div>
				                    </div>
				                    {buttonSubmitLoader}
				
				                </form>
				            )
        } 
        else 
        {
            phoneSection = (
							<div className=" telecom-phone-card">
					                <div className="">
					                    <strong>   رقم الهاتف الذي تود الاشتراك به
					                    </strong>
					                    <br /><br />
					                    <div className="phoneNum" id="right-lift">
					                        <PhoneInput
					                            country={this.state.countryPhoneCode}
					                            placeholder="رقم الهاتف"
					                            name="phone"
					                            value={this.state.phone}
					                            onlyCountries={['eg', 'sa','iq','bh','qa']}
					                            onChange={this.handlePhoneLoginChange} />
					                    </div>
					
					                    <br /> 
					                    {telecomCompanyDropId}
					                    
					                </div>
					            </div>
				            )

            signinForm = (
				                <form className="signin-form">
				
				
				                    <div className="form-group">
				                        <input id="font"
				                            type="email"
				                            className=" filed form-control"
				                            name="email"
				                            placeholder="بريدك الإلكتروني"
				                            dir="auto"
				                            value={this.state.email}
				                            onChange={this.handleChange} />
				                    </div>
				
				                    <div className="form-group">
				
				                        <div className="input-container" style={{ 'position': 'relative' }}>
				                            <input id="font"
				                                type={isRevealPassword ? "text" : "password"}
				                                dir="auto"
				                                className="filed form-control"
				                                name="password"
				                                placeholder="كلمة المرور"
				                                value={this.state.password}
				                                onChange={this.handleChange}
				                                ref={this.passwordOneRef} />
				
				                            <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
				                                <span>
				                                    {isRevealPassword ?
				                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
				                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
				                                    }
				                                </span>
				
				                            </span>
				                        </div>
				
				                    </div>
				
				                    {buttonLoginLoader}
				
				                    <div className="another-ways">
				                        <div className="text">
				                            <p>هل سجَّلت عبر وسيلة أخرى؟</p>
				                            <div className="sign-way">
				                                <a onClick={this.redirect}>تسجيل الدخول عبر وسيلة أخرى.</a>
				                            </div>
				                        </div>
				                    </div>
				                </form>
				          )
        }
        if (this.state.digest && this.state.date && this.state.loaded === false) 
        {
            //alert("inside " + this.state.digest);
            //var date = new Date();
            //var utc_date = date.toISOString();
            this.loadScript("http://enrichment-staging.tpay.me/idxml.ashx/js-staging?date=" + this.state.date + "&digest=" + this.state.digest + "");
        }
        
        let beforePriceDisclaimer = 'سوف تقوم بالاشتراك في خدمة ' + this.state.serviceName + ' مقابل ';
        let afterPriceDisclaimer = this.state.frequency + ' تجدد تلقائيا. ' + ' لالغاء الاشتراك، لعملاء ' + this.state.telecomCompanyText + ' أرسل ' + this.state.keyword + ' الى ' + this.state.shortCode + ' مجانا. ' + ' لأيّة استفسارات يرجي التواصل معنا على ' + this.state.supportMail + '. استهلاك الانترنت سوف يخصم من الباقة الخاصة بك.';
        let disclaimerSection = '';
        
        let iraqBeforeDisclaimer = 'سيحصل المشتركين الجدد لأول مرة على يوم 1 مجاني كلفة الاشتراك 300 دينار يوميا عند توفر رصيد كافي';
        let iraqAfterDisclaimer = ', لإلغاء الاشتراك ارسل 0117 الى  4089للحصول على اي معلومات يرجى الاتصال بنا' + ' hello@jeelapp.com ';
        
        if (this.state.country === CONSTANTS.EGYPT && this.state.countryPhoneCode === CONSTANTS.EGYPT_SHORT_CODE)
            disclaimerSection = (
					                <div style={{ textAlign: 'right' }}>
					                    <div className="text">
					                        <p>
					                            {beforePriceDisclaimer}
					                            <span style={{ color: 'red' }}>{this.state.telecomCompanySubscriptionPrice + ' جنيه مصري '}</span>
					                            {afterPriceDisclaimer}
					                        </p>
					
					                    </div>
					                </div>
					            )
            
if (this.state.country === CONSTANTS.IRAQ && this.state.countryPhoneCode === CONSTANTS.IRAQ_SHORT_CODE)
            disclaimerSection = (
					                <div style={{ textAlign: 'right' }}>
					                    <div className="text">
					                        <p>
					                            {iraqBeforeDisclaimer}
					                            {iraqAfterDisclaimer}
					                        </p>
					
					                    </div>
					                </div>
					            )
                        
        return (
            <body>
                <section className="jeel-fit">
                    <div className="logo-container">
                        <div className="container">
                            <div className="logo">
                                <img src={mainLogoM} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-6">
                                <div className="center fit-about-text">
                                    <div className="title h2 white ">
                                        <center>
                                            أدخل رقم هاتفك واشترك فوراً في تطبيق جيل!
                                            <br />
                                            <span className=" h5">
                                                {this.state.subscriptionMessage}
                                            </span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="  main-progress-bar">
                                    <ol className="progress-tracker">
                                        <li className="step fit">
                                            <span className=" step-name small-font">
                                                <span className="bold">
                                                    الاشتراك
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="notbold">
                                                    رمز التحقق
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="notbold">
                                                    تحميل التطبيق
                                                </span>
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>

                <section className="phone-section" >
                    <div className="row justify-content-md-center ">
                        <div className=" col-12 col-md-6 ">
                            <div className="form-container">

                                {countrySection}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="phone-section" >
                    <div className="row justify-content-md-center ">
                        <div className=" col-12 col-md-6 ">
                            <div className="form-container">

                                {phoneSection}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="phone-section" >
                    <div className="row justify-content-md-center ">
                        <div className=" col-12 col-md-6 ">
                            <div className="form-container">
                               {dailyWeeklyComboSection}
                            </div>
                        </div>
                    </div>
                </section>


                <br /><br />
                
                {loader}
                
                <section className="buttons">
                    <div className="container">
                        <div className="row justify-content-md-center">

                            <div className=" col-12 col-md-6 " id="fit_space" >
                                <p className="h5 text-rtl">

                                    لتفعيل اشتراكك في باقة شركات الاتصالات ،

                                    <strong>
                                        سجل الدخول بحسابك على تطبيق جيل
                                    </strong>

                                </p>
                                <br />
                                <div className="mx-auto text-rtl row">
                                    <p style={{ color: 'red', textAlign: 'right' }}> {this.state.errorMsg}</p>
                                </div>
                            </div>

                            <br /><br />
                        </div>

                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="form-container">
                                    <div className="form-header btn-container">

                                        <button data-toggle-form=".signup-form"
                                            className={"btn toggle-btn signin-btn " +
                                                this.state.activeRegisterBtn}
                                            onClick={this.handleRegisterForm}>
                                            انشاء حساب جديد
                                        </button>

                                        <button data-toggle-form=".signin-form"
                                            className={"btn toggle-btn signup-btn "
                                                + this.state.activeSignInBtn}
                                            onClick={this.handleSignInForm}>
                                            تسجيل دخول</button>


                                    </div>
                                    {registerForm}
                                    {signinForm}
                                    {disclaimerSection}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Testimonials />
                {/** Start Footer **/}
                <Footer />
                {/** End Footer */}
            </body>
        )
    }
}

const mapStateToProps = state => ({
    success: state.authUser.success,
    errorResponse: state.authUser.errorMsg,
    loading: state.authUser.loading,
    telecom: state.authUser.tpayData,
    successMessage: state.successMessages.item,
    exchangeData: state.redirectionPage.item,

    token: state.user.token,
    packagePrice: state.fawryPaymentPrices.item,

    digest: state.tpay.digest,
    paymentMethodName:  state.paymentData.paymentMethod,
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                tpayRegister,
                empty,
                tpayLogin,
                fitLogin,
                fitRegister,
                addSuccessMessage,
                addSeriesIDtoRedirectionPage,
                emptyExchangeData,
                redirectionPage,
                signOut,
                getTelecomPrices,
                emptyTpay,
                getDigest
            })
        (TelecomLogin)
    );
