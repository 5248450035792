import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { withFirebase } from '../../../config';

/* Redux */
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/* Components */
import ParentsTable from './ParentsTable';
import SendCustomerEmail from '../../Customer/SendCustomerEmail';
import SendCustomerNotification from '../../Customer/SendCustomerNotification';

/* Navbar */
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';

/* Actions */
import { getUserData } from '../../../Actions/MobilePaymentProfileAction';

/* Routes */
import { PARTNERHOME } from '../../../routes';

class Parents extends Component {
    state = {
        checkedItems: new Map(),
    };

    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            });
        }
    }

    getCheckedParents = (checkedItems) => {
        if (checkedItems) {
            this.setState({ checkedItems });
        }
    };

    render() {
        const { userPaymentProfile } = this.props;
        let sendMailIcon = '';
        let sendNotificationIcon = '';

        if (userPaymentProfile.user_role.role !== 'Analyst') {
            sendMailIcon = <SendCustomerEmail checkedItems={this.state.checkedItems} />;
            sendNotificationIcon = <SendCustomerNotification checkedItems={this.state.checkedItems} />;
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={PARTNERHOME}>Home</Link><span> &gt; </span>
                                                    </span>
                                                    <span>Parents</span>
                                                </div>
                                                <div className="col-12 left-main-body" id="left-main-body">
                                                </div>
                                                <h5 className="main-title mt-2 font-weight-bold">Parents</h5>

                                                <div className="mt-4 responsive-table">
                                                    <br></br>
                                                    {/* <div className="col-12 left-main-body" id="left-main-body">
                                                        {sendMailIcon}
                                                        {sendNotificationIcon}
                                                    </div> */}
                                                    <div className="card mt-3 p-0">
                                                        <div className="card-body p-4">
                                                            <ParentsTable getListOfCheckedCustomersCallBack={this.getCheckedParents.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
});

export default withFirebase(
    connect(mapStateToProps, { getUserData })(Parents)
);
