import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../../css/DetailedViews.css';

class DetailedViewsReportData extends Component {
    constructor(props) {
        super(props);
        const locationState = props.location.state || {};

        this.state = {
            data: locationState.data || null,
            startDate: locationState.startDate || "12/2/2024",
            endDate: locationState.endDate || "12/3/2024",
        };
    }

    calculateTotalDuration = () => {
        const { data } = this.state;
        if (data) {
            if (data.songsReports) return data.songsReports.reduce((acc, item) => acc + item.number_of_watching, 0);
            if (data.seriesReports) return data.seriesReports.reduce((acc, item) => acc + item.number_of_watching, 0);
            if (data.radioReports) return data.radioReports.reduce((acc, item) => acc + item.number_of_watching, 0);
        }
        return 0;
    };

    render() {
        const { type } = this.props.match.params;
        const { data, startDate, endDate } = this.state;
        const totalDuration = this.calculateTotalDuration();

        if (!data) {
            return <p>No data available. Try navigating through the main dashboard.</p>;
        }

        return (
            <div className="detailed-report">
                <h2>
                    {type === 'songs' ? 'Song Views' :
                    type === 'series' ? 'Series Views' :
                    type === 'radio' ? 'Radio Views' : 'Learning & Skills'}
                </h2>

                {/* Date Range Display */}
                <div className="date-range">
                    <div className="date-input">Start Date: {startDate}</div>
                    <div className="date-input">End Date: {endDate}</div>
                </div>

                {/* Most Viewed and Last Watched Section */}
                <div className="report-summary-heading">Most View</div>
                <div className="report-summary">
                    <div className="report-summary-item">
                        <h3>Last watching:</h3>
                        {type === 'songs' && data.lastWatchedSong && (
                            <>
                                <img src={data.lastWatchedSong.image} alt="Last Watched Song" />
                                <p>{data.lastWatchedSong.name || 'Last Watched Song'}</p>
                            </>
                        )}
                        {type === 'series' && data.lastWatchedSeries && (
                            <>
                                <img src={data.lastWatchedSeries.image} alt="Last Watched Series" />
                                <p>{data.lastWatchedSeries.name || 'Last Watched Series'}</p>
                            </>
                        )}
                        {type === 'radio' && data.lastWatchedRadio && (
                            <>
                                <img src={data.lastWatchedRadio.image} alt="Last Watched Radio" />
                                <p>{data.lastWatchedRadio.name || 'Last Watched Radio'}</p>
                            </>
                        )}
                        {type === 'discover' && data.lastWatchedSeries && (
                            <>
                                <img src={data.lastWatchedSeries.image} alt="Last Watched Learning & Skills" />
                                <p>{data.lastWatchedSeries.name || 'Last Watched Learning & Skills'}</p>
                            </>
                        )}
                    </div>

                    <div className="report-summary-item">
                        <h3>Favorite {type === 'songs' ? 'Song' : type === 'series' ? 'Series' : type === 'radio' ? 'Radio' : 'Learning & Skills'}:</h3>
                        {type === 'songs' && data.mostWatchedSong && (
                            <>
                                <img src={data.mostWatchedSong.image} alt="Most Watched Song" />
                                <p>{data.mostWatchedSong.name || 'Most Watched Song'}</p>
                            </>
                        )}
                        {type === 'series' && data.mostWatchedSeries && (
                            <>
                                <img src={data.mostWatchedSeries.image} alt="Most Watched Series" />
                                <p>{data.mostWatchedSeries.name || 'Most Watched Series'}</p>
                            </>
                        )}
                        {type === 'radio' && data.mostWatchedRadio && (
                            <>
                                <img src={data.mostWatchedRadio.image} alt="Most Watched Radio" />
                                <p>{data.mostWatchedRadio.name || 'Most Watched Radio'}</p>
                            </>
                        )}
                        {type === 'discover' && data.mostWatchedSeries && (
                            <>
                                <img src={data.mostWatchedSeries.image} alt="Most Watched Learning & Skills" />
                                <p>{data.mostWatchedSeries.name || 'Most Watched Learning & Skills'}</p>
                            </>
                        )}
                    </div>
                </div>

                {/* List of Views */}
                <div className="report-list">
                    <h3>
                        {type === 'songs' ? 'Songs' :
                        type === 'series' ? 'Series' :
                        type === 'radio' ? 'Radio Channels' : 'Learning & Skills'}
                    </h3>
                    <p className="total-duration">{totalDuration} Mins Watching</p>

                    {type === 'songs' && data.songsReports && data.songsReports.map((item, index) => (
                        <div className="report-item" key={index}>
                            <img src={item.songImage} alt={item.songName} />
                            <div className="text-container">
                                <h4>{item.songName}</h4>
                                <p>Watch: {item.number_of_watching} mins</p>
                            </div>
                            <button className="details-button">Details</button>
                        </div>
                    ))}

                    {type === 'series' && data.seriesReports && data.seriesReports.map((item, index) => (
                        <div className="report-item" key={index}>
                            <img src={item.seriesImage} alt={item.seriesName} />
                            <div className="text-container">
                                <h4>{item.seriesName}</h4>
                                <p>Watch: {item.number_of_watching} mins</p>
                            </div>
                            <button className="details-button">Details</button>
                        </div>
                    ))}

                    {type === 'radio' && data.radioReports && data.radioReports.map((item, index) => (
                        <div className="report-item" key={index}>
                            <img src={item.radioImage} alt={item.radioName} />
                            <div className="text-container">
                                <h4>{item.radioName}</h4>
                                <p>Listen: {item.number_of_watching} mins</p>
                            </div>
                            <button className="details-button">Details</button>
                        </div>
                    ))}

                    {type === 'discover' && data.seriesReports && data.seriesReports.map((item, index) => (
                        <div className="report-item" key={index}>
                            <img src={item.seriesImage} alt={item.seriesName} />
                            <div className="text-container">
                                <h4>{item.seriesName}</h4>
                                <p>Watch: {item.number_of_watching} mins</p>
                            </div>
                            <button className="details-button">Details</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(DetailedViewsReportData);
