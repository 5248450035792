
import React, { Component } from 'react'
import { DateInput } from 'semantic-ui-calendar-react';
import { Link } from 'react-router-dom'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';


//import ProfitShareTabel from './ProfitShareTabel';
import ProfitRevenuPerCurrencyTabel from './ProfitRevenuPerCurrencyTabel';
import { useState } from "react";
import { useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { post } from 'axios';


//export default function ProfitShareNew() {
const ProfitShareNew = () => {
	const today = new Date();

	const eMonth = today.getMonth() + 1;
	const eDay = today.getDate();
	const eYear = today.getFullYear();

	const oneWeekAgo = new Date(today);
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
	const sMonth = oneWeekAgo.getMonth() + 1;
	const sDay = oneWeekAgo.getDate();
	const sYear = oneWeekAgo.getFullYear();

	const [startDate, setStartDate] = useState(`${sMonth}/${sDay}/${sYear}`)
	const [endDate, setEndDate] = useState(`${eMonth}/${eDay}/${eYear}`)
	const [suggestedProfit, setSuggestedProfit] = useState('')
	const [profitShareList, setProfitShareList] = useState([])
	const [profitShareVendorsList, setProfitShareVendorsList] = useState([])
	const [loading, setLoading] = useState(true);
	const [profitPerCurrencyFetched, setProfitPerCurrencyFetched] = useState(false);
	const [profitPerVendorsFetched, setProfitPerVendorsFetched] = useState(false);


	// =======================================================================================================================================================
	const ProfitPerCurrencyClos = [
		{
			Header: "Profit",
			accessor: "",
			width: 109,
			MinWidth: 140,
			Maxwidth: 140,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {
				return (
					<div style={{ "textAlign": "center" }}> <Link className="link" to={{ pathname: props.original.profit }}> {parseFloat(props.original.profit).toFixed(2)} </Link> </div>
				)
			}
		},
		{
			Header: "Currency",
			accessor: "",
			width: 109,
			MinWidth: 140,
			Maxwidth: 140,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {
				return (
					<div style={{ "textAlign": "center" }}> <Link className="link" to={{ pathname: props.original.currency }}> {props.original.currency} </Link> </div>
				)
			}
		},
		{
			Header: "Profit USD",
			accessor: "",
			width: 109,
			MinWidth: 140,
			Maxwidth: 140,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {
				return (
					<div style={{ "textAlign": "center" }}> <Link className="link" to={{ pathname: props.original.profitWithUSD }}> {parseFloat(props.original.profitWithUSD).toFixed(2)} </Link> </div>
				)
			}
		},
		{
			Header: "Profit EGP",
			accessor: "",
			width: 109,
			MinWidth: 140,
			Maxwidth: 140,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {
				return (
					<div style={{ "textAlign": "center" }}> <Link className="link" to={{ pathname: props.original.profitWithEGP }}> {parseFloat(props.original.profitWithEGP).toFixed(2)} </Link> </div>
				)
			}
		},
		{
			Header: "Rate Today",
			accessor: "",
			width: 109,
			MinWidth: 140,
			Maxwidth: 140,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {
				return (
					<div style={{ "textAlign": "center" }}> <Link className="link" to={{ pathname: props.original.rateToUSD }}> {parseFloat(props.original.rateToUSD).toFixed(2)} </Link> </div>
				)
			}
		},

	]
	// =======================================================================================================================================================        

	// =======================================================================================================================================================
	const profitPerVendors = [

		{
			Header: "Content Vendor Name",
			accessor: "contentVendorName",
			width: 240,
			MinWidth: 250,
			Maxwidth: 250,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {

				return (

					<div style={{ "textAlign": "center" }}>
						<Link className="link" to={{ pathname: props.original.id }}>
							{props.original.contentVendorName}
						</Link>
					</div>
				)
			}
		},
		{
			Header: "profit",
			accessor: "gainedProfit",
			width: 100,
			filterable: false,
			resizable: true,
			headerClassName: 'table-header',
			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<Link className="link" to={{ pathname: props.original.id }}>
							{props.original.gainedProfit ? props.original.gainedProfit.toFixed(2) : 0}
						</Link>
					</div>
				)
			}
		}
	]
	// =======================================================================================================================================================




	const handleSuggestedProfitChange = (e) => {
		setSuggestedProfit(e.target.value);
	}

	const handleDateChange = (e, { name, value }) => {

		//console.log('name: '+name);

		if (name === "startDate") {
			//this.props.updateStartDateInProfit(this.state.startDate);
			setStartDate(value);

			//console.log('startDate-val: '+value + "  , endDate: "+endDate);

			if (endDate) {
				//console.log('goStart: st: '+value+" , en:"+endDate)
				getProfitByCurr(value, endDate);
			}
		}
		if (name === "endDate") {
			//this.props.updateEndDateInProfit(this.state.endDate);
			setEndDate(value);

			//console.log('endDate-val: '+value + "  , startDate: "+startDate);

			if (startDate) {
				//console.log('goEnd: st: '+startDate+" , en:"+value)
				getProfitByCurr(startDate, value);
			}
		}

	}

	const handleSubmitOfSuggestedProfit = (e) => {

		e.preventDefault();

		//console.log('endDate in handleSubmit: '+endDate);
		//console.log('startDate: '+startDate);
		//console.log('suggestedProfit: '+suggestedProfit);

		if (!startDate || !endDate || !suggestedProfit) {
			alert('Please Enter dates and Profit');

			return;
		}

		getProfitByVendors();
	}

	const clearProfits = () => {
		setStartDate('');
		setEndDate('');
		setSuggestedProfit('');

		setProfitShareVendorsList([]);
		setProfitShareList([]);
		setProfitPerCurrencyFetched(false);
		setProfitPerVendorsFetched(false);

	}

	// =====================================================================

	let profitByVendorsFrm = <fieldset className='fieldset'>
		<legend className="legend">Share The Profit</legend>
		<div className="ui form">
			<div className="row">
				<form className="col-12" onSubmit={(e) => e.preventDefault()}>
					<div className="col-4">
						<span style={{ color: "red" }}>* </span>  Revenue To Be Shared <br /><br />
					</div>

					<div className="col-4">
						<input
							type="number"
							dir="auto"
							className="form-control"
							fluid name="suggestedProfit"
							id="suggestedProfit"
							onChange={handleSuggestedProfitChange}
							value={suggestedProfit}
							required />
						<br />

					</div>
					<div class="col-4"> </div>
					<br />
				</form>
			</div>
		</div>

		<div className="box-container">
			<button onClick={handleSubmitOfSuggestedProfit} id="nextButton" disabled={false} >
				Distribute Profits
			</button>

			{/*<button onClick={clearProfits} id="clearButton" disabled={false} className="seriesButtons">
																						                Clear Profit
																						            </button>				*/}
		</div>

	</fieldset>
		;

	// =====================================================================

	useEffect(() => {
		getProfitByCurr(startDate, endDate);
	}, []);


	// ===================================================================== 

	const getProfitByCurr = (startDate, endDate) => {

		const firebase = getFirebase();
		console.log('firebase is :' + firebase);
		firebase.auth().currentUser.getIdToken().then((token) => {

			setLoading(true);
			const url = "/country-subs-report/getProfitByCurrency"
			const formData = new FormData();
			formData.append("Start_Date", startDate)
			formData.append("End_Date", endDate)
			const config = {
				headers: {
					'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
					'X-Authorization-Firebase': token
				}
			}

			//console.log('startDate :'+startDate); 
			//console.log('endDate :'+endDate);
			//console.log('Willlllllllllllll post');
			post(url, formData, config).then((res) => {

				//console.log('res in useEffect : '+JSON.stringify(res.data,null,2));

				setProfitShareList(res.data);

				setLoading(false);
				setProfitPerCurrencyFetched(true);


			}).catch((Error) => {
				console.log('Error while getprofit currency :' + Error)
			})

		})
	}

	// ===================================================================== 

	const getProfitByVendors = () => {

		console.log(' vendors submit:');

		const firebase = getFirebase();
		//console.log('firebase is :'+firebase);
		firebase.auth().currentUser.getIdToken().then((token) => {

			setLoading(true);
			const url = "/country-subs-report/getProfitsByContentVendor";
			const formData2 = new FormData();
			formData2.append("suggestedProfit", suggestedProfit);
			formData2.append("Start_Date", startDate);
			formData2.append("End_Date", endDate);

			//console.log('startDate is :'+startDate);
			//console.log('endDate is :'+endDate);
			//console.log('formData2 is :'+JSON.stringify(formData2,null,2));

			const config = {
				headers: {
					'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
					'X-Authorization-Firebase': token
				}
			}

			post(url, formData2, config).then((res) => {

				//console.log('res in useEffect : '+JSON.stringify(res.data,null,2));

				setProfitShareVendorsList(res.data);
				setProfitPerVendorsFetched(true);

				setLoading(false);


			}).catch((Error) => {
				console.log('Error while getprofit currency :' + Error)
			})

		})
	}

	/* =====================================================================================================================================================*/
	/* =====================================================================================================================================================*/
	/* =====================================================================================================================================================*/


	return (
		<div className="container">
			<OuterHeader />
			<div className="container">
				<div className="container-body">
					<div className="row">
						<Dashboard />
						<div className="col-9">
							<InnerHeader />
							<div className="main-content">
								<div className="row">
									<div className="col-12 left-main-body" id="left-main-body">

										<div className="box-container">


											<h4 className="main-title mt-2 font-weight-bold">
												Profit Share </h4>
											<div className="card mt-3 p-0">

												<div>
													<div id="tab03" className="tab-contents details-table">
														<form onSubmit={(e) => e.preventDefault()}>
															<div className="form-row mt-2">
																<div className="col-md-4 col-12 mb-3">
																	<label className="small-label" for="">Start Date</label>
																	<DateInput
																		name="startDate"
																		closeOnMouseLeave

																		value={startDate}
																		placeholder="start date"
																		onChange={handleDateChange}
																		iconPosition="right"
																		dateFormat="MM/DD/YYYY"
																		required
																	/>
																</div>
																<div className="col-md-4 col-12 mb-3">
																	<label className="small-label" for="">End Date</label>
																	<DateInput
																		name="endDate"
																		closeOnMouseLeave

																		value={endDate}
																		placeholder="end date"
																		onChange={handleDateChange}
																		iconPosition="right"
																		dateFormat="MM/DD/YYYY"
																		required
																	/>
																</div>
															</div>
														</form>
													</div>
												</div>




												<div className="card-body p-4">
													{/* <!-- start form --> */}
													<form className="alluserMng" onSubmit={(e) => e.preventDefault()}>
														<div className="mt-4 responsive-table">
															<ProfitRevenuPerCurrencyTabel profitShareList={profitShareList} columns={ProfitPerCurrencyClos} dataFetched={profitPerCurrencyFetched}>
																<div className="card-body p-4"> Total : &nbsp;&nbsp;
																	{parseFloat(profitShareList.reduce((prev, curr, ind) => { return prev + curr.profitWithEGP }, 0)).toFixed(2)}&nbsp;&nbsp;&nbsp; EGP
																</div>
																<div className="card-body pt-2 pb-2"> Total : &nbsp;&nbsp;
																	{parseFloat(profitShareList.reduce((prev, curr, ind) => { return prev + curr.profitWithUSD }, 0)).toFixed(2)}&nbsp;&nbsp;&nbsp; USD
																</div>
															</ProfitRevenuPerCurrencyTabel>
														</div>


														<div className="mt-4 responsive-table">
															<ProfitRevenuPerCurrencyTabel profitShareList={profitShareVendorsList} columns={profitPerVendors} dataFetched={profitPerVendorsFetched} childrenAbove={profitByVendorsFrm} />
														</div>

													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}


export default ProfitShareNew;
